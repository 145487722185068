// Libraries
import React from 'react';
import { Link } from 'gatsby';

// Styles
import * as styles from '../components/tools.module.css';

const Tool = ({ url, imgSrc, toolName }) => {
  return (
    <li className={styles.toolItem}>
      <Link to={url}>
        <img className={styles.image} alt={`${toolName} image`} src={imgSrc} />
        <h2 className={styles.title}>{toolName}</h2>
      </Link>
    </li>
  );
};

export default Tool;
