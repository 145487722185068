// Libraries
import React from 'react';

// Styles
import * as styles from '../components/tools.module.css';

// Components
import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Tool from '../components/tool';

// Constants
import { DEVELOPMENT } from '../utils/constants';

// Assets
import mapImage from '../../public/map.webp';
import battleMapImage from '../../public/battle-map.png';

const TOOLS = [
  {
    url: '/tools/name-generator',
    name: 'Name Generator',
    imgSrc: mapImage,
    shouldDisplay: true,
  },
  {
    url: '/tools/battle-mapper',
    name: 'Battle Mapper',
    imgSrc: battleMapImage,
    // Only works locally :(
    shouldDisplay: process.env.NODE_ENV === DEVELOPMENT,
  },
];

const Tools = (props) => {
  return (
    <Layout location={props.location}>
      <Seo title="Tools" />
      <Hero title="Tools" content="Tools for use with fantasy gaming" />
      <ul className={styles.toolsContainer}>
        {TOOLS.map(
          ({ url, name, imgSrc, shouldDisplay }, index) =>
            shouldDisplay && (
              <Tool key={index} url={url} toolName={name} imgSrc={imgSrc} />
            )
        )}
      </ul>
    </Layout>
  );
};

export default Tools;
